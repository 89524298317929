
import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import ForgotPasswordDialog from '@/components/common/forgot-password-dialog'
import {mapActions, mapGetters} from "vuex";
import {adminCodes} from "@/enums/adminSettings";

export default {
  name: 'LoginCard',
  components: {
    LabeledControlLayout,
    ForgotPasswordDialog,
  },
  props: {
    contentsFromCms : {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      forgotPasswordDialog: false,
      createNewCustomer: true,
      signInForm: {
        data: {
          email: '',
          password: '',
        },
        emailRules: [
          (v) => !!v || 'Required',
          (v) => /.+@.+\..+/.test(v) || this.$t('Please enter a valid email address'),
        ],
        rules: {
          required: (value) => !!value || 'Required',
          min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        },
        rememberMe: false,
        showPassword: false,
        valid: false,
      },
      loginContantInfo: {
        phone_number:'531-005-555',
        email_address:'fusion@gopiro.com'
      }
    }
  },
  methods: {
    ...mapActions('rememberedUser', ['saveUser','removeSavedUser']),
    ...mapGetters('rememberedUser',['getSavedUserInfo']),
    async signIn() {
      if (this.$refs.signInForm.validate()) {
        try {
          const response = await this.$auth.loginWith('local', {
            data: {
              username: this.signInForm.data.email,
              password: this.signInForm.data.password,
            },
          })

          if (response.status === 200 && response.data.token) {
            if(this.signInForm.rememberMe) {
              this.saveUser({username: this.signInForm.data.email, password: this.signInForm.data.password})
            }
            this.$toast.success('Logged in!')
            this.$emit('loggedIn')
          } else {
            this.$toast.error('Invalid username or password!')
          }
        } catch (err) {
          this.$toast.error('Invalid username or password!')
        }
      }
    },
    signUp() {
      this.$emit('signUpHandler')
    },
    showDialog() {
      this.forgotPasswordDialog = true
    },
    doResetPassword() {
      // todo
    },
    getSavedUser() {
      const userInfo = this.getSavedUserInfo()
      if(userInfo !== null) {
        this.signInForm.rememberMe = true;
        this.signInForm.data.email = userInfo.username;
        this.signInForm.data.password = userInfo.password;
      }
    },
    rememberMeChanged() {
      this.signInForm.rememberMe = !this.signInForm.rememberMe;
      if(!this.signInForm.rememberMe) {
        this.removeSavedUser()
      }
    }
  },
  mounted() {
    this.getSavedUser()
  }
}
