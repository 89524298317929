
import LoginCard from '@/components/common/login-card'
import RegisterCard from '@/components/common/register-card'
import {availableModules} from '@/enums/availableModules'
import {mapActions, mapGetters, mapState} from 'vuex'
import {adminCodes} from '~/enums/adminSettings'

export default {
  components: {
    RegisterCard,
    LoginCard,
  },
  data() {
    return {
      visibleCard: 'login',
      cmsContents: {
        spiffLogo: null,
        underLoginText: null,
        contactEmail: null,
        contactPhone: null,
      }
    }
  },
  head() {
    return {
      title: this.$t('login'),
    }
  },
  computed: {
    IsLoggedIn() {
      return this.$auth.loggedIn
    },
    //...mapState('common/cache', ['loadSettings']),
    //...mapGetters('backoffice/settings', ['adminSettingByCode']),
    ContactPhoneNumber() {
      return this.adminSettingByCode(adminCodes.ContactPhoneNumber).value
    },
    ContactEmailAddress() {
      return this.adminSettingByCode(adminCodes.ContactEmailAddress).value
    },
  },
  async mounted() {
    if (this.IsLoggedIn) {
      //this.proceedToSelectedModule()
    }
    this.getCMSContentsForLogin().then(contents=>{
      this.cmsContents.spiffLogo = contents.details.logo.fieldValue.fullFileUrl;
      this.cmsContents.underLoginText = contents.details.text_under_login.fieldValue.value;
      this.cmsContents.contactEmail = contents.details.contact_email.fieldValue.value;
      this.cmsContents.contactPhone = contents.details.contact_phone.fieldValue.value;
    })
  },
  methods: {
    ...mapActions('common/cache', ['loadingCache']),
    async loggedIn() {
      await this.loadingCache()
      //await this.proceedToSelectedModule()
    },
    async getCMSContentsForLogin() {
      return this.$axios.$get('contents/spiffcontent')
    }
  },
}
